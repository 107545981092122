import React from 'react';
import { Post } from 'models/post';
import { useProfileInfo } from 'src/hooks/useProfileInfo';

const getDefaultData = (authorName: string) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': 'https://evan-moon.github.io',
    },
    author: {
      '@type': 'Person',
      name: authorName,
    },
    publisher: {
      '@type': 'Person',
      name: authorName,
    },
  };
};

const StructuredPostData = ({ post }: { post: Post }) => {
  const profile = useProfileInfo();
  const defaultData = getDefaultData(profile.site.siteMetadata.author.name);
  const data = {
    ...defaultData,
    headline: post.frontmatter.title,
    image: [post.frontmatter.thumbnail?.childImageSharp.fluid?.src],
    datePublished: post.frontmatter.date,
    dateModified: post.frontmatter.date,
  };

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }} />;
};

export default StructuredPostData;
