import React from 'react';
import { Box, Button, makeStyles, Theme } from '@material-ui/core';
import { Link } from 'gatsby';
import { Post } from 'models/post';
import LanguageFlag from 'components/Utils/LanguageFlag';
import { useCallback } from 'react';
import { SupportedLanguages } from 'models/site';

const LANGUAGE_NAME: { [key in SupportedLanguages]: string } = {
  ko: '한국어',
  en: 'English',
};

const useStyle = makeStyles<Theme>(({ spacing }) => ({
  noStyle: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    textDecoration: 'none',
  },
  flag: {
    marginRight: spacing(1),
  },
}));

interface Props {
  otherLangPosts: Array<Pick<Post, 'fields'>>;
  onClick?: () => void;
}
const OtherLangPosts = ({ otherLangPosts, onClick }: Props) => {
  const styles = useStyle();
  const handleClick = useCallback(() => {
    onClick?.();
  }, [onClick]);

  return (
    <Box>
      <ul className={styles.noStyle}>
        {otherLangPosts.map(({ fields: post }) => (
          <li className={styles.noStyle} key={post.path}>
            <Link to={post.path} className={styles.noStyle} onClick={handleClick}>
              <Button variant="outlined" size="large">
                <span className={styles.flag}>
                  <LanguageFlag lang={post.lang} />
                </span>
                {LANGUAGE_NAME[post.lang]}
              </Button>
            </Link>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default OtherLangPosts;
