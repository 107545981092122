import { useState } from 'react';
import useAsyncEffect from './useAsyncEffect';
import firebase from 'gatsby-plugin-firebase';

export const usePostViewCount = (slug: string) => {
  const [viewCount, setViewCount] = useState<number | undefined>(undefined);

  useAsyncEffect(async () => {
    const snapshot = await firebase.database().ref('views').child(slug).once('value');
    setViewCount(snapshot.val() ?? 1);
  }, [slug]);

  return viewCount;
};
