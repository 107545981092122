import React from 'react';
import { graphql } from 'gatsby';
import PostPage from 'containers/PostPage';

const PostTemplate = (props: any) => {
  return <PostPage {...props} />;
};

export default PostTemplate;

export const pageQuery = graphql`
  query PostBySlugQuery($slug: String!, $lang: String!, $postGroup: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(truncate: true)
      html
      tableOfContents(absolute: false, maxDepth: 3)
      fields {
        lang
        slug
      }
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        categories
        tags
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(filter: { fields: { postGroup: { eq: $postGroup }, lang: { ne: $lang } } }) {
      edges {
        node {
          fields {
            path
            lang
          }
        }
      }
    }
  }
`;
