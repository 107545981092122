import React, { useMemo, ComponentProps } from 'react';
import { useAllPosts } from 'hooks/useAllPosts';
import intersection from 'lodash/intersection';
import { Box, Typography, Paper, makeStyles, Theme } from '@material-ui/core';
import SimplePostItem from 'components/Items/SimplePostItem';
import { Post } from 'models/post';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  root: {
    padding: spacing(2),
  },
  title: {
    marginBottom: spacing(2),
  },
}));

interface Props extends ComponentProps<typeof Paper> {
  currentPost: Post;
  tags: string[];
  onClickPost?: (path: string) => void;
}
const RelatedPosts = ({ tags, currentPost, onClickPost, ...rest }: Props) => {
  const MAX_COUNT = 5;
  const styles = useStyles();
  const posts = useAllPosts();
  const relatedPosts = useMemo(() => {
    return posts
      .filter(post => post.frontmatter.title !== currentPost.frontmatter.title)
      .map(post => ({
        post,
        tags: intersection(post.frontmatter.tags ?? [], tags),
      }))
      .filter(({ tags }) => tags.length > 0)
      .sort((a, b) => b.tags.length - a.tags.length)
      .map(v => v.post)
      .slice(0, MAX_COUNT);
  }, [currentPost, posts, tags]);

  if (relatedPosts.length === 0) {
    return null;
  }

  return (
    <Paper className={styles.root} variant="outlined" {...rest}>
      <Typography className={styles.title}>관련 포스팅 보러가기</Typography>
      <Box>
        {relatedPosts.map(post => (
          <SimplePostItem
            key={post.fields.path}
            thumbnail={post.frontmatter.thumbnail?.childImageSharp?.fixed?.src ?? ''}
            title={post.frontmatter.title ?? ''}
            date={post.frontmatter.date}
            path={post.fields.path ?? '/'}
            categories={post.frontmatter.categories}
            onClick={onClickPost}
          />
        ))}
      </Box>
    </Paper>
  );
};

export default RelatedPosts;
