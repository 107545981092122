import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ shape, palette, spacing, typography }) => ({
  root: {
    lineHeight: 1.8,
    color: palette.grey['800'],
    '& h2, & h3, &, h4': {
      color: '#000000',
    },
    '& h2': {
      margin: `${spacing(4)}px 0 ${spacing(2)}px`,
    },
    '& h3': {
      margin: `${spacing(3.5)}px 0 ${spacing(2)}px`,
    },
    '& h4': {
      margin: `${spacing(3)}px 0 ${spacing(2)}px`,
    },
    '& h5': {
      fontSize: typography.body1.fontSize,
    },
    '& p': {
      wordBreak: 'break-all',
    },
    '& a': {
      color: palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& small': {
      fontSize: typography.caption.fontSize,
    },
    '& hr': {
      backgroundColor: palette.grey['300'],
      height: 1,
      border: 'none',
      display: 'block',
      margin: `${spacing(3)}px 0`,
    },
    '& blockquote': {
      backgroundColor: palette.grey['200'],
      borderLeft: `5px solid ${palette.grey['400']}`,
      padding: `${spacing(2.5)}px ${spacing(2.8)}px`,
      margin: `${spacing(3)}px 0`,
      textAlign: 'left',
    },
    '& span.gatsby-resp-image-wrapper': {
      borderRadius: shape.borderRadius,
      overflow: 'hidden',
      margin: `${spacing(5)}px 0 ${spacing(2)}px`,
      '& + small': {
        display: 'block',
        color: palette.grey['700'],
        marginBottom: `${spacing(5)}px`,
      },
    },
    '& table': {
      width: '100%',
      borderCollapse: 'collapse',
      '& thead': {
        backgroundColor: palette.grey['100'],
      },
      '& thead th': {
        borderRight: `1px solid ${palette.common.white}`,
      },
      '& tr': {
        borderBottom: `1px solid ${palette.grey['200']}`,
      },
      '& td, & th': {
        padding: `${spacing(1)}px ${spacing(1.5)}px`,
        textAlign: 'left',
        border: 'none',
        wordBreak: 'break-all',
        '&[align="center"]': {
          textAlign: 'center',
        },
      },
    },
    '& span.katex': {
      overflow: 'scroll',
    },
    '& span.katex-display': {
      margin: `${spacing(4)}px 0`,
    },
  },
}));

interface Props {
  html: string;
}
const Markdown = ({ html }: Props) => {
  const styles = useStyles();
  return <div className={styles.root} dangerouslySetInnerHTML={{ __html: html }} />;
};

export default Markdown;
