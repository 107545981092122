import React, { useMemo, ReactNode } from 'react';
import { SupportedLanguages } from 'models/site';

interface Props {
  lang: SupportedLanguages;
}
const LanguageFlag = ({ lang }: Props) => {
  const flag: { [key in SupportedLanguages]: ReactNode } = useMemo(
    () => ({
      ko: (
        <span role="img" aria-label="ko">
          🇰🇷
        </span>
      ),
      en: (
        <span role="img" aria-label="en">
          🇬🇧
        </span>
      ),
    }),
    []
  );

  return <>{flag[lang]}</>;
};

export default LanguageFlag;
