import React from 'react';
import { Card, Grid, CardActionArea, CardContent, makeStyles, Theme } from '@material-ui/core';
import { Post } from 'models/post';
import { Link } from 'gatsby';
import Emoji from 'components/Utils/Emoji';

const useStyles = makeStyles<Theme>(({ spacing, palette }) => ({
  navCard: {
    marginBottom: spacing(2),
    '& .MuiCardContent-root': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  next: {
    textAlign: 'right',
  },
  link: {
    color: palette.grey['600'],
    textDecoration: 'none',
  },
}));

interface Props {
  prev?: Post | null;
  next?: Post | null;
  onClickNext?: () => void;
  onClickPrev?: () => void;
}
const Navigation = ({ prev, next, onClickNext, onClickPrev }: Props) => {
  const styles = useStyles();
  return (
    <Grid container component="nav" spacing={2} justify="space-between" wrap="wrap">
      {prev ? (
        <Grid item xs={12} lg={6}>
          <Card className={`${styles.navCard} ${styles.prev}`} variant="outlined">
            <Link to={prev.fields.path ?? '/'} rel="prev" className={styles.link} onClick={onClickPrev}>
              <CardActionArea>
                <CardContent>
                  <Emoji emoji="👈" name="previous" /> {prev.frontmatter.title}
                </CardContent>
              </CardActionArea>
            </Link>
          </Card>
        </Grid>
      ) : (
        <Grid />
      )}
      {next ? (
        <Grid item xs={12} lg={6}>
          <Card className={`${styles.navCard} ${styles.next}`} variant="outlined">
            <Link to={next.fields.path ?? '/'} rel="next" className={styles.link} onClick={onClickNext}>
              <CardActionArea>
                <CardContent>
                  {next.frontmatter.title} <Emoji emoji="👉" name="next" />
                </CardContent>
              </CardActionArea>
            </Link>
          </Card>
        </Grid>
      ) : (
        <Grid />
      )}
    </Grid>
  );
};

export default Navigation;
