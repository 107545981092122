import React, { useCallback, MouseEvent } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ spacing, typography, shape, palette }) => ({
  root: {
    '& ul': {
      padding: 0,
      margin: 0,
    },
    '& li': {
      padding: 0,
      margin: 0,
      listStyle: 'none',
      '& p': {
        margin: 0,
      },
      '& a': {
        display: 'block',
        padding: `${spacing(1)}px ${spacing(1.5)}px ${spacing(1)}px ${spacing(0.75)}px`,
        marginLeft: spacing(0.75),
        color: palette.grey['800'],
        textDecoration: 'none',
        fontSize: typography.caption.fontSize,
        borderRadius: shape.borderRadius,
        '&:hover': {
          backgroundColor: palette.grey['100'],
        },
      },
    },
    '& li ul': {
      margin: `0 0 ${spacing(1.5)}px ${spacing(1.5)}px`,
      borderLeft: `1px solid ${palette.grey['200']}`,
    },
  },
}));

interface Props {
  tocHtml: string;
  onClick?: (value: string) => void;
}
const TocCard = ({ tocHtml, onClick }: Props) => {
  const styles = useStyles();
  const handleClickList = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      try {
        if (target.tagName !== 'A') {
          return;
        }
        const value = decodeURIComponent((target as HTMLAnchorElement).innerText);
        onClick?.(value);
      } catch (e) {
        console.error(e);
      }
    },
    [onClick]
  );

  return <div onClick={handleClickList} dangerouslySetInnerHTML={{ __html: tocHtml }} className={styles.root} />;
};

export default TocCard;
