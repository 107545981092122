import React, { useCallback, memo } from 'react';
import { Grid, Chip, makeStyles, Box, Theme } from '@material-ui/core';
import { Post } from 'models/post';
import Markdown from 'components/Utils/Markdown';
import { Link } from 'gatsby';
import { usePageRouteUtils } from 'hooks/usePageRouteUtils';
import RelatedPosts from './RelatedPosts';
import PostHeader from './Header';
import Languages from './Languages';
import { Logger } from 'utils/loggers';

const useStyles = makeStyles<Theme>(({ spacing, typography }) => ({
  thumbnail: {
    paddingTop: '42.8%',
    display: 'block',
    position: 'relative',
    marginBottom: spacing(2),
    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      margin: 0,
    },
  },
  languages: {
    margin: `${spacing(4)}px 0`,
  },
  relatedPosts: {
    marginTop: spacing(4),
  },
  tagWrapper: {
    margin: 0,
    padding: 0,
    marginTop: spacing(4),
  },
  tags: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    lineHeight: 1.1,
    '& a': {
      textDecoration: 'none',
      fontSize: typography.caption.fontSize,
    },
  },
}));

interface Props {
  post: Post;
  viewCount?: number;
  otherLangPosts: Array<Pick<Post, 'fields'>>;
  logger?: Logger;
}
const PostContents = ({ post, viewCount, otherLangPosts, logger }: Props) => {
  const styles = useStyles();
  const { getTagPath } = usePageRouteUtils();
  const {
    frontmatter: { title, date, categories, tags, thumbnail },
    html,
  } = post;

  const handleOtherLanguageClick = useCallback(() => {
    logger?.click('click_posting_language');
  }, [logger]);

  const handleRelatedPostClick = useCallback(
    (path: string) => {
      logger?.click('click_related_posting', {
        path,
      });
    },
    [logger]
  );

  const handleTagClick = useCallback(
    (tagName: string) => () =>
      logger?.click('click_tag', {
        tagName,
      }),
    [logger]
  );

  return (
    <>
      {thumbnail && (
        <div className={styles.thumbnail}>
          <img src={thumbnail?.childImageSharp?.fluid?.src} alt={title} title={title} />
        </div>
      )}
      <PostHeader title={title ?? ''} date={date} viewCount={viewCount} categories={categories ?? []} />
      {otherLangPosts.length > 0 && (
        <Box className={styles.languages}>
          <Languages otherLangPosts={otherLangPosts} onClick={handleOtherLanguageClick} />
        </Box>
      )}
      <Markdown html={html ?? ''} />
      {tags && (
        <Grid container className={styles.tagWrapper} component="ul" spacing={1}>
          {tags.map((tag, index) => (
            <Grid item component="li" key={index} className={styles.tags}>
              <Link to={getTagPath(tag)}>
                <Chip label={tag} size="small" onClick={handleTagClick(tag)} variant="outlined" />
              </Link>
            </Grid>
          ))}
        </Grid>
      )}
      <section className={styles.relatedPosts}>
        <RelatedPosts tags={tags ?? []} currentPost={post} onClickPost={handleRelatedPostClick} />
      </section>
    </>
  );
};

export default memo(PostContents);
