import React, { useEffect } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Toc from 'components/Post/PostContents/Toc';
import Navigation from './Navigation';
import { Post } from 'models/post';
import PostContents from 'components/Post/PostContents';
import { generateLogger } from 'utils/loggers';
import { increaseView } from 'utils/remotes/post';
import { isProduction } from 'utils/env';
import { usePostViewCount } from 'hooks/usePostViewCount';
import StructuredPostData from 'components/Utils/StructuredPostData';

const logger = generateLogger('post_page');
const handleNextClick = () => {
  logger.click('click_next_post');
};
const handlePrevClick = () => {
  logger.click('click_prev_post');
};
const handleTocClick = (contents: string) => {
  logger.click('click_toc', { contents });
};

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  contentWrapper: {
    marginBottom: spacing(4),
  },
  tocWrapper: {
    marginLeft: spacing(1),
  },
  shareWrapper: {},
}));

interface Props {
  data: {
    markdownRemark: Post;
    allMarkdownRemark: {
      edges: Array<{ node: Pick<Post, 'fields'> }>;
    };
  };
  pageContext: {
    previous: any;
    next: any;
  };
  location: Location;
}

const PostPage = ({ data, pageContext }: Props) => {
  const styles = useStyles();
  const {
    markdownRemark: post,
    allMarkdownRemark: { edges: otherLangPosts },
  } = data;
  const { tableOfContents, frontmatter, excerpt } = post;
  const { previous, next } = pageContext;
  const thumbnail = frontmatter.thumbnail?.childImageSharp?.fluid;
  const viewCount = usePostViewCount(post.fields.slug);

  useEffect(() => {
    if (isProduction) {
      increaseView(post.fields.slug);
    }
  }, [post.fields.slug]);

  useEffect(() => {
    logger.view();
    logger.impression(`show_posting`, { title: post.frontmatter.title });
  }, [post.frontmatter.title]);

  return (
    <Layout
      right={
        <Box display={{ xs: 'none', lg: 'block' }} className={styles.tocWrapper}>
          <Toc tocHtml={tableOfContents ?? ''} onClick={handleTocClick} />
        </Box>
      }
      rightSticky={true}
    >
      <SEO title={frontmatter.title} description={excerpt} image={thumbnail?.src} />
      <Box className={styles.contentWrapper}>
        <PostContents
          post={post}
          viewCount={viewCount}
          otherLangPosts={otherLangPosts.map(v => v.node)}
          logger={logger}
        />
      </Box>
      <Navigation prev={previous} next={next} onClickNext={handleNextClick} onClickPrev={handlePrevClick} />
      <StructuredPostData post={post} />
    </Layout>
  );
};

export default PostPage;
