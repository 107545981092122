import React, { Fragment, useMemo } from 'react';
import { Box, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import { usePageRouteUtils } from 'hooks/usePageRouteUtils';
import Emoji from 'components/Utils/Emoji';

const useStyles = makeStyles<Theme>(({ spacing, palette, typography }) => ({
  root: {
    marginBottom: spacing(5),
  },
  info: {
    color: palette.grey['500'],
  },
  time: {
    fontSize: typography.caption.fontSize,
    marginRight: spacing(1),
  },
  categoryLink: {
    color: 'inherit',
    textDecoration: 'none',
    fontSize: typography.caption.fontSize,
    '&:hover': {
      color: palette.primary.main,
    },
  },
  title: {
    fontSize: typography.h5.fontSize,
    margin: `0 0 ${spacing(3)}px`,
  },
}));

interface Props {
  title: string;
  date: string;
  categories: string[];
  viewCount?: number;
}
const PostHeader = ({ title, date, categories, viewCount }: Props) => {
  const styles = useStyles();
  const { getCategoryPath } = usePageRouteUtils();
  const formattedViewCount = useMemo(() => viewCount?.toLocaleString('ko-KR'), [viewCount]);

  return (
    <header className={styles.root}>
      <Box className={styles.info}>
        <time className={styles.time}>{date}</time>
        <span>
          {categories.map((category, i) => (
            <Fragment key={i}>
              <Link className={styles.categoryLink} to={getCategoryPath(category)}>
                {category.toUpperCase()}
              </Link>
              {i !== categories.length - 1 && ' / '}
            </Fragment>
          ))}
        </span>
      </Box>

      <h1 className={styles.title}>{title}</h1>
      {formattedViewCount && (
        <Typography variant="caption">
          <strong>{formattedViewCount}</strong>명의 사람들이 읽어봤어요 <Emoji emoji="👀" name="view count" />
        </Typography>
      )}

      <Divider />
    </header>
  );
};

export default PostHeader;
